import ReactDOM from 'react-dom';
import cx from 'classnames';

import stopPropagation from 'utils/eventFunc';
import { useSetBodyOverflow } from 'utils/customHooks';

import FilledClose from 'public/icons/filled_close.svg';

import styles from './modal.module.css';

const PopUp = ({
  children, title, handleClose, open = false, modalClass = '', innerClass = '', titleClass = '',
}) => {
  useSetBodyOverflow([open]);

  if (!open) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <div aria-hidden="true" role="button" className={cx(styles.modal, 'flex-center', modalClass)} onClick={handleClose}>
        <div aria-hidden="true" role="button" onClick={stopPropagation} className={cx(styles.box, innerClass)}>
          <FilledClose width={32} height={32} onClick={handleClose} className={styles.close} />
          <h3 className={cx(styles.title, titleClass, 'text-2xl')}>{title}</h3>
          {children}
        </div>
      </div>,
      document.body,
    )
  );
};

export default PopUp;
