import {
  useMemo, useEffect, useRef, useState, useContext,
} from 'react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import WhiteBox from 'components/elems/whitebox';
import { MediaContext } from 'components/mediaQuery';

import {
  FootballScores,
  FootballScoresStrings,
  extractScoresAsId,
  getScoreSum,
  FootballS1,
  FootballS2,
  FootballS3,
  PoolStateToStatus,
} from 'utils/game';
import { formatMsToOpt } from 'utils/date';

import Check from 'public/icons/check_circle.svg';
import { ScoreGridCell, Other } from './scoreGridCell';
import Scores from './scores';

import styles from './probability.module.css';

const isActive = (scoreGrid, active) => {
  const extracted = extractScoresAsId(active);
  return scoreGrid.some((it) => extracted.some((ext) => it.includes(ext)));
};

const Container = ({
  className,
  index,
  time,
  team1,
  team2,
  state,
  maxLines,
  currentScore,
  currentActive,
  activeScores,
  probability,
  addNewLine,
  activeHandler,
  deleteHandler,
  setActive,
  activeScroll,
}) => {
  const containerRef = useRef();
  const { mobile } = useContext(MediaContext);
  const predictions = useMemo(() => probability && JSON.parse(probability), [probability]);
  const scoreToString = (s, pred, grid) => {
    if (s === FootballScores.MaxZero) {
      return <Other pred={pred} grid={grid} team={team1} />;
    }
    if (s === FootballScores.ZeroMax) {
      return <Other pred={pred} grid={grid} team={team2} />;
    }
    if (s === FootballScores.Even) {
      return <Other pred={pred} grid={grid} />;
    }

    return FootballScoresStrings[`${s}`];
  };
  const [openTippy, setOpenTippy] = useState(false);
  const [showed, setShowed] = useState(false);

  useEffect(() => {
    if (activeScroll) {
      window.scrollTo({
        top: containerRef.current?.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [activeScroll]);

  useEffect(() => {
    const extracted = extractScoresAsId(activeScores[0]);
    const isOnlyOne = extracted.length === 1;
    setOpenTippy(isOnlyOne && !showed);
  }, [activeScores, showed]);

  const canceled = state === PoolStateToStatus.CANCELED;

  return (
    <section ref={containerRef} className={cx(styles.section, { [styles.canceled]: canceled }, className)}>
      <header className="mb-4 text-center">
        Match
        {' '}
        {index}
        <time className={cx(styles.time, 'pl-3 text-sm')}>
          {formatMsToOpt(time * 1000)}
        </time>
      </header>
      <WhiteBox className={cx(styles.container, 'pb-4')}>
        <table className={cx('overflow-hidden w-full', { 'pointer-events-none': maxLines === 0 })}>
          <thead>
            <tr className={styles.tableHeader}>
              <th className="text-center py-4 px-1 w-2/5">
                <div className={styles.headerInner}>
                  <img className={styles.teamLogo} src={`/logos/${team1.abbr}.png`} />
                  {team1.name}
                  {' '}
                  <span>win</span>
                </div>
              </th>
              <th className="text-center py-4 px-1">Draw</th>
              <th className="text-center py-4 px-1 w-2/5">
                <div className={styles.headerInner}>
                  <img className={styles.teamLogo} src={`/logos/${team2.abbr}.png`} />
                  {team2.name}
                  {' '}
                  <span>win</span>
                </div>
              </th>
            </tr>
            {(probability && maxLines !== 0 && !canceled) && (
              <tr className={styles.scoresRow}>
                <td className={cx(styles.scoreRow, 'text-left py-2 px-1')} colSpan={3}>
                  <div className={styles.scoreInner}>
                    <Scores
                      activeScores={activeScores}
                      addNewLine={addNewLine}
                      deleteActive={deleteHandler}
                      setActive={setActive}
                      maxLines={maxLines}
                      currentActive={currentActive}
                      predictions={predictions}
                      team1={team1}
                      team2={team2}
                    />
                  </div>
                </td>
              </tr>
            )}
            <tr className={styles.subRow}>
              <th colSpan="3" className={cx(styles.subTitle, 'text-center py-1 px-1')}>
                Score / Main
              </th>
            </tr>
          </thead>
          {(probability && !canceled) ? (
            <tbody>
              <Tippy
                visible={openTippy}
                placement={mobile ? 'top' : 'bottom'}
                content={(
                  <>
                    Click any other score
                    <br />
                    {' '}
                    for a
                    <strong className="ml-1">combo scores</strong>
                  </>
                  )}
                onClickOutside={() => setOpenTippy(false)}
                onShow={(instance) => {
                  setTimeout(() => {
                    instance.hide();
                    setShowed(true);
                  }, 2000);
                }}
              >
                <tr className={styles.mainRow}>
                  <ScoreGridCell
                    scoreGrid={FootballS1}
                    predictions={predictions}
                    onClickListener={activeHandler}
                    scoreToString={scoreToString}
                    active={currentScore}
                    setOpenTippy={setOpenTippy}
                  />
                  <ScoreGridCell
                    scoreGrid={FootballS2}
                    pos="center"
                    predictions={predictions}
                    onClickListener={activeHandler}
                    scoreToString={scoreToString}
                    active={currentScore}
                    setOpenTippy={setOpenTippy}
                  />
                  <ScoreGridCell
                    scoreGrid={FootballS3}
                    pos="right"
                    predictions={predictions}
                    onClickListener={activeHandler}
                    scoreToString={scoreToString}
                    active={currentScore}
                    setOpenTippy={setOpenTippy}
                  />
                </tr>
              </Tippy>
              <tr className={styles.subRow}>
                <td colSpan="3" className={cx(styles.subTitle, 'text-center py-1 px-1')}>
                  <strong>Result / Backup (auto)</strong>
                </td>
              </tr>
              <tr>
                <td className="text-left px-1">
                  <span className={cx(styles.scoreCell, styles.big, {
                    [styles.active]: isActive(FootballS1, currentScore),
                  }, 'text-sm')}
                  >
                    <span>
                      {`${team1.abbr} win`}
                      <strong>/</strong>
                      { mobile && <Check className={cx(styles.checkIcon, 'w-4 h-4 inline-block ml-1')} /> }
                    </span>
                    <span>
                      {' '}
                      {getScoreSum(FootballS1, predictions)}
                      { !mobile && <Check className={cx(styles.checkIcon, 'w-4 h-4 inline-block ml-1')} /> }
                    </span>
                  </span>
                </td>
                <td className="text-center px-1">
                  <span className={cx(styles.scoreCell, styles.big, {
                    [styles.active]: isActive(FootballS2, currentScore),
                  }, 'text-sm')}
                  >
                    <span>
                      Draw
                      <strong>/</strong>
                      { mobile && <Check className={cx(styles.checkIcon, 'w-4 h-4 inline-block ml-1')} /> }
                    </span>
                    <span>
                      {' '}
                      {getScoreSum(FootballS2, predictions)}
                      { !mobile && <Check className={cx(styles.checkIcon, 'w-4 h-4 inline-block ml-1')} /> }
                    </span>
                  </span>
                </td>
                <td className="text-right px-1">
                  <span className={cx(styles.scoreCell, styles.big, {
                    [styles.active]: isActive(FootballS3, currentScore),
                  }, 'text-sm')}
                  >
                    <span>
                      {`${team2.abbr} win`}
                      <strong>/</strong>
                      { mobile && <Check className={cx(styles.checkIcon, 'w-4 h-4 inline-block ml-1')} /> }
                    </span>
                    <span>
                      {' '}
                      {getScoreSum(FootballS3, predictions)}
                      { !mobile && <Check className={cx(styles.checkIcon, 'w-4 h-4 inline-block ml-1')} /> }
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="text-center pt-6" colSpan="3">
                  { canceled ? 'This event was cancelled 😢' : 'Odds not available yet 😅' }
                  {!canceled && (
                    <p className="text-sm px-4 pt-2">
                      You can always choose scores for these events later. We will notify you when the probability will be available. &#128521;
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </WhiteBox>
    </section>
  );
};

export default Container;
