import cx from 'classnames';
import Score from 'components/elems/score';
import { scoresToString, getProbability, getSideWinAndProbability } from 'utils/game';
import Prediction from 'components/bets/probability/prediction';

import Add from 'public/icons/add.svg';
import FilledClose from 'public/icons/filled_close.svg';
import Calculator from './calculator';

import styles from './probability.module.css';

const Scores = ({
  activeScores,
  addNewLine,
  deleteActive,
  setActive,
  currentActive,
  maxLines,
  predictions,
  team1,
  team2,
}) => {
  const activesLen = activeScores.length;
  const lastNotEmpty = Boolean(activeScores[activesLen - 1]);
  const addLine = activesLen > 0 && activesLen < maxLines && lastNotEmpty;

  return (
    <>
      {
        activeScores.map((it, i) => {
          const items = scoresToString(it);
          const notEmpty = Boolean(it);
          const canDelete = deleteActive && notEmpty;
          const [value, pred] = getSideWinAndProbability(it, team1, team2, predictions);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${items}-${i}`} className={styles.scoreBox}>
              <Score
                onClick={() => setActive(i)}
                className={styles.scoreItem}
                disabled={i !== currentActive}
              >
                {notEmpty && (
                  <Calculator
                    scores={it}
                    team1={team1}
                    team2={team2}
                    probability={predictions}
                  />
                ) }
                { !items && 'Add score ↓' }
                { notEmpty && (
                  <span className="mt-1 block leading-6 font-bold">
                    <span>
                      { items }
                      <Prediction big>{getProbability(it, predictions)}</Prediction>
                    </span>
                    <span className="block">
                      {value}
                      <Prediction big>{pred}</Prediction>
                    </span>
                  </span>
                )}
                { canDelete && (
                  <FilledClose
                    onClick={(ev) => {
                      ev.stopPropagation();
                      deleteActive(i);
                    }}
                    className={cx(styles.close, 'w-6 h-6 ml-3')}
                  />
                ) }
              </Score>
            </div>
          );
        })
      }
      { addLine && (
        <div className={styles.scoreBox}>
          <span className="text-xs font-bold">{`Line ${activeScores.length + 1} (optional)`}</span>
          <Score onClick={() => addNewLine(activeScores.length)} className={styles.scoreItem}>
            Line
            <Add className={cx(styles.icon, 'w-6 h-6 ml-2')} />
          </Score>
        </div>
      )}
    </>
  );
};

export default Scores;
