import cx from 'classnames';
import { useState, useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { round } from 'utils/number';
import {
  extractScoresAsId, getResultByScore, scoresToString, extractedToString, getProbability, probToCoef,
} from 'utils/game/football';
import CalculatorSvg from 'public/icons/calculator.svg';

import styles from './probability.module.css';

const Calculator = ({
  scores, team1, team2, probability, className,
}) => {
  const [openTippy, setOpenTippy] = useState(false);
  const extracted = extractScoresAsId(scores);
  const mapProb = useMemo(() => {
    const newProp = {};
    for (const key in probability) {
      if (probability.hasOwnProperty(key)) {
        newProp[key] = Number(((probability[key] * 100) / 100).toFixed(2));
      }
    }
    return newProp;
  }, [probability]);

  const sides = extracted.reduce((acc, n) => {
    const res = getResultByScore(scoresToString(n).split(':'), team1, team2, mapProb);
    if (!acc.find((it) => it.val === res.val)) {
      acc.push(res);
    }

    return acc;
  }, []);

  const prob = extracted.map((score) => mapProb[score]);
  const sumScores = prob.reduce((acc, n) => acc + n, 0);
  const sumProb = sides.map((it) => it.prob).reduce((acc, n) => acc + n, 0);

  const extractedString = extractedToString(extracted);

  const resultValues = sides.map((it) => it.val);
  const result = round((100.0 - sumScores) / sumProb, 2);

  return (
    <Tippy
      visible={openTippy}
      placement="bottom"
      className={styles.calculatorWrapper}
      content={(
        <div className={styles.calculatorTippy}>
          <strong className={styles.tippyTitle}>Score / Main</strong>
          <div className={styles.tippyBox}>
            <div>
              100% / (
              <span className={styles.small}>All results</span>
            </div>
            {prob.map((it, i) => (
              <div key={`${it}-${i}`}>
                &nbsp;
                {`${i !== 0 ? ' + ' : ''}${round(it, 2)}%`}
                <span className={cx(styles.small, {
                  [styles.smallSp]: i,
                  [styles.smallFr]: i === 0,
                })}
                >
                  {extractedString[i]}
                </span>
              </div>
            ))}
            <div>
              &nbsp;)&nbsp;
            </div>
            <div>
              {` = ${round(getProbability(scores, mapProb), 2)}`}
              <span className={cx(styles.small, styles.result)}>{scoresToString(scores)}</span>
            </div>
          </div>
          <strong className={styles.tippyTitle}>Result / Backup</strong>
          <div className={styles.tippyBox}>
            <div>
              ( 100%
              <span className={styles.small}>All results</span>
            </div>
            {prob.map((it, i) => (
              <div key={`${it}-${i}`}>
                &nbsp;
                {`- ${round(it, 2)}%`}
                <span className={cx(styles.small, styles.smallPr)}>{extractedString[i]}</span>
              </div>
            ))}
            <div>
              &nbsp;) /
            </div>
            {sides.length > 1 && (
              <div>&nbsp;(</div>
            )}
            {sides.map((it, i) => (
              <div key={`${it.prob}-${i}`}>
                &nbsp;
                {`${i !== 0 ? ' + ' : ''}${round(it.prob, 2)}%`}
                <span className={cx(styles.small, {
                  [styles.smallSp]: i,
                  [styles.smallFrS]: i === 0,
                })}
                >
                  {resultValues[i]}
                </span>
              </div>
            ))}
            {sides.length > 1 && (
              <div>&nbsp;)</div>
            )}
            <div>
              &nbsp;
              {`= ${round(result, 2)}`}
              <span className={cx(styles.small, styles.result)}>{resultValues.join(' or ')}</span>
            </div>
          </div>
        </div>
      )}
      onClickOutside={() => setOpenTippy(false)}
    >
      <div className={cx('mr-3 flex flex-col items-center font-bold', className)} style={{ fontSize: '10px', lineHeight: 1 }}>
        See
        <CalculatorSvg
          className={cx(styles.calculator, 'w-6 h-6')}
          onClick={(ev) => {
            ev.stopPropagation();
            setOpenTippy(true);
          }}
        />
        calculations
      </div>
    </Tippy>
  );
};

export default Calculator;
