/* eslint react/no-array-index-key: 0 */
import { useContext, cloneElement } from 'react';
import cx from 'classnames';
import { MediaContext } from 'components/mediaQuery';
import { extractScoresAsId, probToCoef } from 'utils/game';
import Prediction from 'components/bets/probability/prediction';

import OtherInfo from './otherInfo';

import styles from './probability.module.css';

const ScoreGridCell = ({
  scoreGrid, pos = 'left', predictions, scoreToString, onClickListener = () => {}, active,
}) => {
  const { mobile } = useContext(MediaContext);

  return (
    <td
      align={pos}
      className={cx(`text-${pos} align-top px-1`, {
        [styles.cellCenter]: pos === 'center',
      })}
    >
      {scoreGrid.map((scores, i) => (
        <div key={i} className={styles.scoreLine}>
          {scores.map((score, ind) => {
            if (!predictions[score]) {
              return null;
            }
            const elem = scoreToString(score, predictions, scoreGrid);
            const big = typeof elem === 'object';

            return (
              <span
                key={ind}
                role="button"
                className={cx(styles.scoreCell, {
                  [styles.active]: extractScoresAsId(active).includes(score),
                }, 'text-sm')}
                onClick={() => onClickListener(score)}
                aria-hidden="true"
              >
                <span className={cx({ [styles.bigScore]: mobile && big })}>
                  {elem}
                </span>
                <Prediction big={big}>
                  {probToCoef(predictions[score])}
                </Prediction>
                {(!mobile && big) && (
                <OtherInfo elem={elem} predictions={predictions} scoreGrid={scoreGrid} />
                )}
              </span>
            );
          })}
        </div>
      ))}
    </td>
  );
};

const Other = ({ team, pred, grid }) => {
  const { mobile } = useContext(MediaContext);

  return (
    <>
      <span>
        Other
        {mobile && (
          <OtherInfo elem={team ? `${team.abbr} win` : 'Draw'} predictions={pred} scoreGrid={grid} />
        )}
      </span>
      {
        team && (
          <>
            {team.abbr}
          </>
        )
      }
      {' '}
      { team ? 'win' : 'Draw' }
    </>
  );
};

export { ScoreGridCell, Other };
