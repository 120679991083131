import cx from 'classnames';

import styles from './probability.module.css';

const Prediction = ({ children, big }) => (
  <span className={cx(styles.prediction, { [styles.horz]: big })}>
    {children}
  </span>
);

export default Prediction;
