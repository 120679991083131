import cx from 'classnames';

import styles from './score.module.css';

const Score = ({
  className,
  children,
  disabled,
  onClick,
}) => (
  <span
    role={onClick ? 'button' : 'none'}
    onClick={onClick}
    className={cx(styles.score, 'score-item-gl text-sm px-4 whitespace-no-wrap', {
      [styles.disabled]: disabled,
    }, className)}
  >
    {children}
  </span>
);

export default Score;
