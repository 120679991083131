const local = 'en-GB';

const formatMsToDate = (ms) => {
  if (!ms) {
    return '';
  }

  const date = new Date(ms);

  return new Intl.DateTimeFormat(local).format(date);
};

const formatMsToOpt = (ms, monthType = 'long') => {
  if (!ms) {
    return '';
  }

  const options = {
    hour: 'numeric', minute: 'numeric', month: monthType, day: 'numeric', year: 'numeric', timeZoneName: 'short',
  };

  const date = new Date(ms);

  return new Intl.DateTimeFormat(local, options).format(date);
};

const formatMsToOptShort = (ms) => {
  if (!ms) {
    return '';
  }

  const options = {
    hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric', year: 'numeric', timeZoneName: 'short',
  };

  const date = new Date(ms);

  return new Intl.DateTimeFormat(local, options).format(date);
};

const formatForInput = (date) => {
  if (date) {
    return date.toISOString().split('T')[0];
  }
  return '';
};

export {
  formatMsToDate, formatMsToOpt, formatForInput, formatMsToOptShort,
};
